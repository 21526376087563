.section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2rem 1rem;
	width: 100%;
	max-width: none;
}

.section .section-title {
	text-align: center;
	font-size: 3rem;
	font-weight: bold;
	margin-bottom: 1.5rem;
}

.section--light {
	background-color: var(--white);
	color: var(--primary-dark);
}

.section--dark {
	background-color: var(--primary-dark);
	color: var(--white);
	max-width: none;
}