.hero-section {
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
}

.hero-section h1 {
	color: var(--white);
	font-size: 5rem;
	padding: 1rem 3rem 2rem;
	text-shadow: 0 0 20px var(--black);
}

.hero-section p {
	color: var(--white);
	font-size: 1.6rem;
	font-weight: 600;
	letter-spacing: 0.6rem;
	text-shadow: 0 0 20px var(--black);
}

@media (max-width: 768px) {
	.hero-section h1 {
		font-size: 3.5rem;
		padding: 1rem;
	}

	.hero-section p {
		font-size: 1.4rem;
		font-weight: 500;
		letter-spacing: 0.5rem;
		padding: 1rem;
	}
}