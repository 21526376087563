.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1.1rem;
}

.btn:hover {
    transition: all 0.3s ease-out;
}

.btn--primary {
    background-color: var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: var(--white);
    border: 1px solid var(--primary-light);
}

.btn--outline:hover {
    background-color: var(--white);
    color: var(--primary);
}

.btn--transparent {
    background-color: transparent;
    color: var(--primary-light);
}

.btn--transparent:hover {
    background-color: var(--primary);
    color: var(--white);
}

.btn-medium {
    padding: 8px 20px;
    border-radius: 4px;
    font-size: 18px;
    color: #fff;
}

.btn-large {
    padding: 12px 26px;
    border-radius: 4px;
    font-size: 20px;
    color: #fff;
}

.btn--ios {
    padding: 2rem 6rem;
    border-radius: 0.8rem;
}

.btn--ios:hover {
    scale: 1.02;
    translate: 0 -3px;
    box-shadow: 0 0.5rem 1.5rem var(--primary-light);
}