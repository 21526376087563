.image-section {
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
}

.image-section quote {
    font-style: oblique;
	color: var(--white);
	font-size: 1.6rem;
	font-weight: 600;
	letter-spacing: 0.6rem;
	text-shadow: 0 0 20px var(--black);
    margin-bottom: 2rem;
}

.image-section quote.dark {
	color: var(--primary-dark);
}

.image-section p {
	color: var(--white);
	font-size: 3rem;
	font-weight: 600;
	letter-spacing: 0.6rem;
	text-shadow: 0 0 20px var(--black);
}

.image-section p.dark {
	color: var(--primary-dark);
}

@media (max-width: 768px) {
	.image-section p {
		font-size: 1.4rem;
		font-weight: 500;
		letter-spacing: 0.5rem;
		padding: 1rem;
	}
}