/* Center the title */
.blog-post h3 {
    text-align: center; /* Center-align the title */
    font-size: 1.5rem; /* Adjust size for emphasis */
    margin-bottom: 0.5em;
    margin-top: 1.2em;
}

.blog-post h4 {
    font-weight: 400;
    font-style: italic;
    margin-top: 0.3em; /* Add spacing below */
    margin: 2em auto 0.5em auto;
    max-width: 80%;
    padding: 0 1em;
}

/* Style the date */
.date {
    text-align: center; /* Center-align the date */
    font-size: 0.9rem; /* Smaller font size */
    color: #777; /* Subtle gray color */
    margin-bottom: 1em; /* Add spacing below the date */
}

/* Style the text paragraphs */
p {
    max-width: 80%; /* Limit width for large screens */
    word-wrap: break-word; /* Ensure long words break */
    margin: 1em auto; /* Center align and add spacing */
    line-height: 1.6;
    font-size: 1rem;
    padding: 0 1em; /* Add padding for smaller screens */
}

/* Style code blocks */
pre {
    background-color: #f5f5f5; /* Light gray background */
    border: 1px solid #ddd; /* Subtle border */
    border-radius: 5px; /* Rounded corners */
    padding: 1em; /* Add inner spacing */
    max-width: 80%; /* Limit width for large screens */
    margin: 1em auto; /* Center align and add spacing */
    overflow-x: auto; /* Enable horizontal scroll for very long lines */
    font-family: 'Courier New', Courier, monospace; /* Monospaced font */
    font-size: 0.9rem; /* Adjust font size */
    color: #333; /* Dark gray text */
}