* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Sen", sans-serif;
}

:root {
    --primary: #7604F1;
    --secondary: #C71A42;

    --primary-dark: #310264;
    --secondary-dark: #3D0814;

    --primary-light: #CA9BFD;
    --secondary-light: #F4A4B5;

    --white: #fff;
    --black: #000;
    --grey: #888;
    --grey-light: #ccc;
    --grey-dark: #444;
}
  
.home,
.about,
.contact,

.services,
.servicesWeb,
.servicesMobile,
.servicesCloud,
.servicesAI,

.projects,
.projectsAntsteroids,
.projectsInvokeTheWarlock,
.projectsIllTechnique,
.projectsRadPM,
.projectsThisSite,
.projectsSilks,
.projectsBujt,
.projectsBustaBlox,
.projectsSamijz,
.projectsPipsWeirdMirror {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    flex-wrap: wrap;
    width: 100%;
}

html {
    scroll-behavior: smooth;
}