/* General styles */
.text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem;
    gap: 1.5rem;
  }
  
  .text-section h2 {
    font-size: 2.5rem;
    font-weight: 800;
    letter-spacing: 0.3rem;
    margin-bottom: 1.3rem;
  }

  .text-section h3 {
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.2rem;
  }

  .text-section p {
    font-size: 1.2rem;
    font-weight: 200;
    letter-spacing: 0.1rem;
    text-align: justify;
  }
  
  .text-section h2, .text-section h3, .text-section p {
    opacity: 0;
    transform: translateY(30px); /* Starts off 30px below */
    transition: opacity 0.5s ease, transform 0.5s ease;
    max-width: 80%;
    line-height: 1.8rem;
  }
  
  /* When element is in view */
  .text-section h2.in-view, 
  .text-section h3.in-view, 
  .text-section p.in-view {
    opacity: 1;
    transform: translateY(0); /* Move to its normal position */
  }
  
  .text-section.light {
    background-color: var(--white);
    color: var(--primary-dark);
  }
  
  .text-section.dark {
    background-color: var(--primary-dark);
    color: var(--white);
  }
  
  