.footer {
    background-color: var(--white);
    color: var(--primary-dark);
    text-align: center;
    padding: 20px 0;
    margin-top: 20px;
}
  
.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    flex-wrap: wrap;
}

.footer-nav-links {
    display: flex;
    gap: 15px;
}

.footer-link {
    color: var(--secondary);
    text-decoration: none;
    font-size: 14px;
    padding: 0.5rem 0.8rem;
    transition: all 0.3s ease-out;
}

.footer-link:hover {
    color: var(--primary-dark);
    border-radius: 5px;
    translate: 0px 3px;
    box-shadow: 0px 5px 15px var(--secondary); /* Highlight color */
}

.footer-social-links {
    display: flex;
    gap: 10px;
}

.footer-icon {
    color: var(--secondary);
    font-size: 18px;
    text-decoration: none;
    padding: 0.5rem 0.8rem;
    transition: all 0.3s ease-out;
}

.footer-icon:hover {
    color: var(--primary-dark);
    border-radius: 5px;
    translate: 0px 3px;
    box-shadow: 0px 5px 15px var(--secondary); /* Highlight color */
}

.footer-bottom {
    margin-top: 10px;
    font-size: 12px;
    opacity: 0.8;
}
  