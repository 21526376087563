/* Base styles for the section */
.info-section {
    display: flex;
    flex-direction: column; /* Stack h2 on top */
    align-items: center;
    padding: 2rem;
    gap: 1.5rem; /* Space between h2 and content wrapper */
    transition: background-color 0.3s ease;
}

/* Light and dark themes */
.info-section.light {
    background-color: var(--white);
    color: var(--primary-dark);
}

.info-section.dark {
    background-color: var(--primary-dark);
    color: var(--white);
}

/* h2 styling */
.info-section h2 {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 1rem;
    width: 100%;
}

/* Flex container for content divs */
.info-section .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 80%; /* Restrict total content width */
    margin: 0 auto; /* Center the content */
    gap: 3rem;
}

/* Text content styling */
.text-content {
    /* flex: 0 0 40%; Take 40% of the wrapper */
    /* max-width: 40%; Enforce max-width for consistency */
    /* text-align: left; */
    flex: 1;
    /* background-color: rgba(255, 255, 255, 0.1);
    border-radius: 1rem;
    padding: 2rem; */
}

.text-content h3 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
}

.text-content p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;
}

/* Button styling */
.text-content .btn--primary {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: var(--primary-dark);
    background-color: var(--white);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: all 0.3s ease-out;
}

.text-content .btn--primary:hover {
    background-color: var(--primary);
    color: var(--white);
}

/* Media content styling */
.media-content {
    /* flex: 0 0 40%; Take 40% of the wrapper */
    /* max-width: 40%; Enforce max-width for consistency */
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.media-content img,
.media-content video {
    max-width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 5px 10px var(--secondary-dark);
}

/* Reverse layout when image is on the right */
.info-section.reverse .content-wrapper {
    flex-direction: row-reverse; /* Swap text and media */
}


/* RESPONSIVE DESIGN */
@media (max-width: 768px) {
    .info-section .content-wrapper {
        flex-direction: column !important; /* Stack text and media vertically */
        width: 100%; /* Full width for mobile */
        align-items: center; /* Center both content blocks */
        gap: 2rem; /* Add vertical space between text and media */
    }

    .text-content,
    .media-content {
        flex: 0 0 auto; /* Allow content to size naturally */
        max-width: 90%; /* Ensure content fits comfortably within the screen */
        margin: 0 auto; /* Center horizontally */
    }

    .media-content img,
    .media-content video {
        max-width: 100%; /* Ensure media scales to fit container */
        height: auto; /* Maintain aspect ratio */
    }

    .text-content {
        text-align: center; /* Center-align text for better mobile experience */
    }

    .text-content ul {
        list-style: none;
    }

    li + li{
        margin: 1rem 0;
    }

    /* Flip the order on small screens */
    .info-section .text-content {
        order: 2; /* Text appears below media */
    }

    .info-section .media-content {
        order: 1; /* Media appears above text */
    }
}
