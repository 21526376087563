.navbar {
    background: linear-gradient(90deg, var(--primary-dark) 0%, var(--black) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
	transition: all 0.8s ease;
}

.brand {
	color: var(--white);
	justify-self: start;
	margin-left: 20px;
	cursor: pointer;
	font-size: 2.4rem;
	text-decoration: none;
}

.menu-icon-times {
	background: url('../../assets/icons/times.svg');
	background-size: 2.4rem 2.4rem;
	height: 2.4rem;
	width: 2.4rem;
	display: block;
}

.menu-icon-burger {
	background: url('../../assets/icons/burger.svg');
	background-size: 2.4rem 2.4rem;
	height: 2.4rem;
	width: 2.4rem;
	display: block;
}

.nav-menu {
	display: grid;
	grid-template-columns: repeat(5, auto);
	grid-gap: 10px;
	list-style: none;
	text-align: center;
	width: 70vw;
	justify-content: end;
	margin-right: 2rem;
}

.menu-icon {
  	display: none;
}

@media screen and (max-width: 960px) {
	.navbar {
		position: relative;
	}

	.nav-menu {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100vh;
		position: absolute;
		top: 80px;
		left: -100%;
		opacity: 1;
		transition: all 0.5s ease;
		justify-content: start;
	}

	.nav-menu.active {
		background: var(--primary-dark);
		left: 0;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 1;
	}

	.nav-item {
		text-align: center;
		padding: 2rem;
		width: 100%;
		display: table;
	}

	.nav-item:hover {
		background: var(--primary);
		border-radius: 0;
		transition: all 0.3s ease-out;
	}

	.brand {
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(10%, 30%);
	}

	.menu-icon {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-50%, 50%);
		cursor: pointer;
	}
}
