.card {
    padding: 1rem;
    border: 1px solid var(--white);
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
    background: var(--white);
    transition: all 0.3s;
  }

  .card.light {
    color: var(--primary-dark);
    border: 1px solid var(--primary-dark);
  }
  
  .card:hover {
    box-shadow: 0 4px 10px var(--primary-dark);
    translate: 0 -5px;
    scale: 1.02;
  }
  
  .card.light:hover {
    box-shadow: 0 4px 10px var(--white);
  }

  .card img {
    max-width: 100%;
    border-radius: 4px;
    border: 2px solid;
  }

  .card h3 {
    padding: 1rem;
  }

  .card.dark {
    color: var(--white);
    background-color: var(--primary-dark);
  }