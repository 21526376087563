.card-section > h2 {
    font-size: 2rem;
    padding: 1.5rem;
}

.card-section.dark {
    background-color: var(--primary-dark);
}

.card-section.dark > h2{
    color: var(--white);
}

.card-section.light > h2{
    color: var(--primary-dark);
}

.card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    width: 80%;
    max-width: none;
}

