.contact-form-section {
    width: 100%
}

/* General Form Styling */
.contact-form-section form {
    display: flex;
    flex-wrap: wrap; /* Enable wrapping for smaller screens */
    gap: 20px; /* Space between items */
    margin: 0 auto;
    width: 70%;
    padding: 4rem;
    border: 3px solid var(--primary-dark);
    border-radius: 8px;
    background-color: var(--white);
    box-shadow: 0 4px 6px var(--primary-dark);
}

/* Topic Select */
.topic-select {
    flex: 1 1 30%; /* Take 30% width on larger screens */
    min-width: 200px; /* Prevent shrinking too small */
}

.topic-select label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--primary-dark);
}

.topic-select select {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--primary-dark);
    border-radius: 4px;
    font-size: 16px;
}

/* Message Area */
.message-area {
    flex: 1 1 60%; /* Take 60% width on larger screens */
    min-width: 300px; /* Prevent shrinking too small */
    display: flex;
    flex-direction: column;
}

.message-area label {
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--primary-dark);
}

.message-area textarea {
    flex: 1;
    width: 100%;
    padding: 10px;
    border: 1px solid var(--primary-dark);
    border-radius: 4px;
    font-size: 16px;
    resize: vertical; /* Allow vertical resizing */
}

/* Submit Button */
.contact-submit {
    flex: 1 1 100%; /* Full width for button */
    padding: 12px 20px;
    background-color: var(--primary);
    color: var(--white);
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .topic-select, .message-area, .contact-submit {
        flex: 1 1 100%; /* Stack everything vertically */
    }
}
